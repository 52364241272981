import { Component, HostListener, input } from '@angular/core'
import { ScaleToPercentPipe } from '@shared/pipes/scale-to-percent.pipe'
import { TooltipDirective } from '@shared/directives/tooltip.directive'
import { FCanvasComponent, FFlowModule, FZoomDirective } from '@foblex/flow'

@Component({
  selector: 'app-zoom-menu',
  standalone: true,
  imports: [FFlowModule, ScaleToPercentPipe, TooltipDirective],
  templateUrl: './zoom-menu.component.html',
  styleUrl: './zoom-menu.component.scss',
})
export class ZoomMenuComponent {
  canvas = input.required<FCanvasComponent | undefined>()
  zoom = input.required<FZoomDirective | undefined>()

  getScale() {
    return this.zoom()!.getScale()
  }

  zoomIn() {
    this.zoom()?.zoomIn()
    return false
  }

  zoomOut() {
    this.zoom()?.zoomOut()
    return false
  }

  resetZoom() {
    this.canvas()?.resetScaleAndCenter(true)
  }

  fullScreen() {
    this.canvas()?.fitToScreen()
  }

  @HostListener('window:keydown.control.+')
  keyboardZoomIn() {
    this.zoomIn()
  }

  @HostListener('window:keydown.control.-')
  keyboardZoomOut() {
    this.zoomOut()
  }
}

import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
class Pipeline {
  handle(request, injector) {
    let isValid = true;
    if (this.validator) {
      isValid = injector.get(this.validator).handle(request);
    }
    if (!isValid) {
      return;
    }
    return injector.get(this.execution).handle(request);
  }
  setValidator(validator) {
    this.validator = validator;
  }
  setExecution(execution) {
    this.execution = execution;
  }
}
class FMediator {
  constructor(injector) {
    this.injector = injector;
  }
  static registerPipeline(type, handler, isValidator) {
    const pipeline = this.pipelines.get(type.name) || new Pipeline();
    isValidator ? pipeline.setValidator(handler) : pipeline.setExecution(handler);
    this.pipelines.set(type.name, pipeline);
  }
  send(request) {
    const pipeline = FMediator.pipelines.get(request.constructor.name);
    if (pipeline) {
      return pipeline.handle(request, this.injector);
    }
    throw new Error('Handler not registered for request type.');
  }
}
FMediator.pipelines = new Map();
FMediator.ɵfac = function FMediator_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FMediator)(i0.ɵɵinject(i0.Injector));
};
FMediator.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FMediator,
  factory: FMediator.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
function FExecutionRegister(requestType) {
  return function (constructor) {
    FMediator.registerPipeline(requestType, constructor, false);
  };
}
function FValidatorRegister(requestType) {
  return function (constructor) {
    FMediator.registerPipeline(requestType, constructor, true);
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { FExecutionRegister, FMediator, FValidatorRegister, Pipeline };

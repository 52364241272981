import { Component, signal } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { NetworkService } from '@shared/services/network.service'
import { OfflineComponent } from '@shared/components/offline/offline.component'
import { OnlineComponent } from '@shared/components/online/online.component'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { delay, tap } from 'rxjs'

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [RouterOutlet, OfflineComponent, OnlineComponent],
  styleUrl: './app.component.scss',
})
export class AppComponent {
  online = signal<boolean | undefined>(undefined)

  constructor(private networkService: NetworkService) {
    this.networkService.onlineStatus
      .pipe(
        takeUntilDestroyed(),
        tap(() => this.online.set(true)),
        delay(5000),
        tap(() => this.online.set(undefined)),
      )
      .subscribe()

    this.networkService.offlineStatus.pipe(takeUntilDestroyed()).subscribe(() => this.online.set(false))
  }
}

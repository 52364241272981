@if (globalService.barsVisibility()) {
  <div class="canvas__top-left-bar canvas__bar" @fadeIn @fadeOut>
    <app-main-menu></app-main-menu>
    <input
      type="text"
      class="placeholder:text-neutral-400 text-neutral-900 px-4 py-3 bg-neutral-100 outline-none min-w-[135px]"
      placeholder="Untitled Project"
    />
  </div>
}

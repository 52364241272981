@if (globalService.barsVisibility()) {
  <div class="canvas__top-right-bar canvas__bar" @fadeIn @fadeOut>
    <button
      type="button"
      class="me-2"
      [appTooltip]="'Tamer Okail (You)'"
      data-tooltip-nowrap
      data-tooltip-bottom-center
    >
      <span
        class="bg-rose-100 text-rose-900 flex flex-row items-center justify-center w-[32px] h-[32px] rounded-full overflow-hidden"
        >T</span
      >
    </button>
    <app-export-menu></app-export-menu>
  </div>
}

import { Component } from '@angular/core'
import { MainMenuComponent } from '@shared/components/main-menu/main-menu.component'
import { GlobalService } from '@shared/services/global.service'
import { fadeIn, fadeOut } from '@shared/animations/animation'

@Component({
  animations: [fadeIn(500), fadeOut(300)],
  selector: 'app-top-left-bar',
  standalone: true,
  imports: [MainMenuComponent],
  templateUrl: './top-left-bar.component.html',
  styleUrl: './top-left-bar.component.scss',
})
export class TopLeftBarComponent {
  constructor(public globalService: GlobalService) {}
}

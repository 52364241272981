import { Component } from '@angular/core'
import { TooltipDirective } from '@shared/directives/tooltip.directive'
import { GlobalService } from '@shared/services/global.service'
import { fadeIn, fadeOut } from '@shared/animations/animation'
import { ExportMenuComponent } from '@shared/components/export-menu/export-menu.component'

@Component({
  animations: [fadeIn(500), fadeOut(300)],
  selector: 'app-top-right-bar',
  standalone: true,
  imports: [TooltipDirective, ExportMenuComponent],
  templateUrl: './top-right-bar.component.html',
  styleUrl: './top-right-bar.component.scss',
})
export class TopRightBarComponent {
  constructor(public globalService: GlobalService) {}
}

import { Pipe, PipeTransform } from '@angular/core'
import { ToolItem } from '@shared/interfaces'
import { ToolsCategories } from '@shared/types'

@Pipe({
  name: 'categoryTools',
  standalone: true,
})
export class CategoryToolsPipe implements PipeTransform {
  transform(tools: ToolItem[], category: ToolsCategories): ToolItem[] {
    return tools.filter(tool => tool.category === category)
  }
}

import { Component, signal } from '@angular/core'
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive'

@Component({
  selector: 'app-export-menu',
  standalone: true,
  imports: [ClickOutsideDirective],
  templateUrl: './export-menu.component.html',
  styleUrl: './export-menu.component.scss',
})
export class ExportMenuComponent {
  visible = signal<boolean>(false)
}

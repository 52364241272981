@if (globalService.barsVisibility()) {
  <div class="canvas__bottom-center-bar canvas__bar" @fadeIn @fadeOut @fadeInCraft @fadeOutCraft>
    <button type="button" class="canvas__control" [appTooltip]="'Test'" data-tooltip-nowrap data-tooltip-top-center>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            id="Vector"
            d="M2.85706 2.93652C4.57658 1.08476 17.5107 5.62094 17.5 7.27711C17.4879 9.15517 12.4489 9.73292 11.0522 10.1247C10.2123 10.3604 9.98733 10.6019 9.79367 11.4827C8.91658 15.4714 8.47625 17.4553 7.47259 17.4997C5.87281 17.5704 1.17893 4.74368 2.85706 2.93652Z"
            stroke="#737373"
            stroke-width="1.5"
          />
        </g>
      </svg>
    </button>
    <button type="button" class="canvas__control" [appTooltip]="'Test'" data-tooltip-nowrap data-tooltip-top-center>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            id="Vector"
            d="M6.57667 7.79723L4.56171 9.96592C4.29305 10.2425 3.81358 10.9643 3.75573 11.6255C3.69488 12.3208 4.32131 13.3182 4.56171 13.7C5.18833 14.6952 5.44063 15.2474 6.17368 16.1893C6.57177 16.7008 7.64711 17.7204 9.39763 17.8489C10.7044 17.9448 12.0051 17.9358 12.6215 17.8489C13.0448 17.7892 14.0723 17.5999 15.0395 16.6042C16.0067 15.6085 16.2485 13.9766 16.2485 13.2851V6.64682C16.2485 6.23192 16.0067 5.40214 15.0395 5.40214C14.0723 5.40214 13.8305 6.23192 13.8305 6.64682V9.55109M6.57667 11.6255V4.98725C6.57667 4.57236 6.81846 3.74257 7.78564 3.74257C8.75279 3.74257 8.99463 4.57236 8.99463 4.98725M8.99463 4.98725V8.72126M8.99463 4.98725V3.32768C8.99463 2.91279 9.23638 2.08301 10.2036 2.08301C11.1708 2.08301 11.4125 2.91279 11.4125 3.32768V4.98725M11.4125 4.98725V8.72126M11.4125 4.98725C11.4125 4.57236 11.6544 3.74257 12.6215 3.74257C13.5887 3.74257 13.8305 4.57236 13.8305 4.98725V7.06171"
            stroke="#737373"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
    <div class="border-r border-l border-neutral-200 mx-2 px-2">
      <input type="text" placeholder="Let's craft something" class="canvas__chat-input" />
    </div>
    <button type="button" class="canvas__control" [appTooltip]="'Test'" data-tooltip-nowrap data-tooltip-top-center>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            id="Vector"
            d="M11.6666 17.0837V16.667C11.6666 14.31 11.6666 13.1315 12.3989 12.3992C13.1311 11.667 14.3096 11.667 16.6666 11.667H17.0833"
            stroke="#737373"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M10.8333 2.5H9.16667C6.02397 2.5 4.45262 2.5 3.47631 3.47631C2.5 4.45262 2.5 6.02397 2.5 9.16667V10.8333C2.5 13.976 2.5 15.5474 3.47631 16.5237C4.45262 17.5 6.02397 17.5 9.16667 17.5H10.2859C10.9672 17.5 11.3078 17.5 11.6141 17.3732C11.9204 17.2462 12.1613 17.0054 12.643 16.5237L16.5237 12.643C17.0054 12.1613 17.2462 11.9204 17.3732 11.6141C17.5 11.3078 17.5 10.9672 17.5 10.2859V9.16667C17.5 6.02397 17.5 4.45262 16.5237 3.47631C15.5474 2.5 13.976 2.5 10.8333 2.5Z"
            stroke="#737373"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>
}

import { Injectable } from '@angular/core'
import { fromEvent, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  onlineStatus: Observable<Event>
  offlineStatus: Observable<Event>

  constructor() {
    this.onlineStatus = fromEvent(window, 'online')
    this.offlineStatus = fromEvent(window, 'offline')
  }
}

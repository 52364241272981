import { Component, output } from '@angular/core'
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common'
import { ToolItem } from '@shared/interfaces'
import { Tools, ToolsCategories } from '@shared/types'
import { ToolsPipe } from '@shared/pipes/tools.pipe'
import { SuggestedToolsPipe } from '@shared/pipes/suggested-tools.pipe'
import { CategoryToolsPipe } from '@shared/pipes/category-tools.pipe'

@Component({
  selector: 'app-tools-menu',
  standalone: true,
  imports: [
    ClickOutsideDirective,
    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    NgTemplateOutlet,
    SuggestedToolsPipe,
    CategoryToolsPipe,
  ],
  providers: [ToolsPipe],
  templateUrl: './tools-menu.component.html',
  styleUrl: './tools-menu.component.scss',
})
export class ToolsMenuComponent {
  clickedOutside = output<boolean>()
  form: FormGroup
  readonly tools: ToolItem[] = [
    {
      alias: Tools.chat,
      category: ToolsCategories.ideate,
      description: 'Engage in conversations to brainstorm ideas',
      name: 'Chat',
      icon: 'tools-chat',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.stakeholders_kickoff,
      category: ToolsCategories.discover,
      description: 'Create a discussion guide to collect input stakeholders',
      name: 'Stakeholders kickoff',
      icon: 'tools-stakeholders',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.summarize,
      category: ToolsCategories.discover,
      description: 'Summarize data by extracting key ideas and themes',
      name: 'Summarize',
      icon: 'tools-summarize',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.desk_research,
      category: ToolsCategories.discover,
      description: 'Gather insights from existing resources',
      name: 'Desk research',
      icon: 'tools-desk-research',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.interview_guide,
      category: ToolsCategories.discover,
      description: 'Create discussion guides that help collect insights',
      name: 'Interview guide',
      icon: 'tools-interview-guide',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.affinity_mapping,
      category: ToolsCategories.define,
      description: 'Organize unstructured data into meaningful clusters',
      name: 'Affinity mapping',
      icon: 'tools-affinity-mapping',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.persona,
      category: ToolsCategories.define,
      description: 'Define user personas based on research insights',
      name: 'User persona',
      icon: 'tools-persona',
      link: '',
      suggested: true,
    },
    {
      alias: Tools.user_journey_mapping,
      category: ToolsCategories.define,
      description: "Visualize the user's journey and experience",
      name: 'Journey mapping',
      icon: 'tools-journey-mapping',
      link: '',
      suggested: true,
    },
    {
      alias: Tools.how_might_we,
      category: ToolsCategories.define,
      description: 'Explore opportunities based on pain points',
      name: 'How might we',
      icon: 'tools-how-might-we',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.site_map,
      category: ToolsCategories.design,
      description: 'Structure content to help users navigate easily',
      name: 'Sitemap',
      icon: 'tools-sitemap',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.ux_copywriting,
      category: ToolsCategories.design,
      description: 'Craft clear and concise text to enhance user experience.',
      name: 'Copywriting',
      icon: 'tools-copywriting',
      link: '',
      suggested: false,
    },
    {
      alias: Tools.design_review,
      category: ToolsCategories.design,
      description: 'Evaluate a design to improve its effectiveness.',
      name: 'Design review',
      icon: 'tools-design-review',
      link: '',
      suggested: false,
    },
  ]

  get filteredTools(): ToolItem[] {
    return this.toolsPipe.transform(this.tools, this.form.get('search')?.value)
  }

  constructor(
    private fb: FormBuilder,
    private toolsPipe: ToolsPipe,
  ) {
    this.form = this.fb.group({
      search: [''],
    })
  }

  protected readonly ToolsCategories = ToolsCategories
}

import { Pipe, PipeTransform } from '@angular/core'
import { ToolItem } from '@shared/interfaces'

@Pipe({
  name: 'suggestedTools',
  standalone: true,
})
export class SuggestedToolsPipe implements PipeTransform {
  transform(tools: ToolItem[]): ToolItem[] {
    return tools.filter(tool => tool.suggested)
  }
}

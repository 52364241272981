<section class="canvas">
  <app-top-left-bar></app-top-left-bar>
  <app-top-right-bar></app-top-right-bar>
  <app-tools-bar></app-tools-bar>
  <app-bottom-left-bar></app-bottom-left-bar>
  <app-bottom-center-bar></app-bottom-center-bar>
  <app-bottom-right-bar [canvas]="fCanvasComponent" [zoom]="fZoomDirective"></app-bottom-right-bar>
</section>

<f-flow fDraggable>
  <f-background>
    <f-circle-pattern></f-circle-pattern>
  </f-background>
  <f-canvas fZoom (fCanvasChange)="canvasChange($event)">
    <f-connection fOutputId="output1" fInputId="input1"></f-connection>
    <div
      fNode
      fDragHandle
      [fNodePosition]="{ x: 524, y: 124 }"
      fNodeOutput
      fOutputId="output1"
      fOutputConnectableSide="right"
    >
      Drag me
    </div>
    <div
      fNode
      fDragHandle
      [fNodePosition]="{ x: 944, y: 224 }"
      fNodeInput
      fInputId="input1"
      fInputConnectableSide="left"
    >
      Drag me
    </div>
  </f-canvas>
</f-flow>

import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations'

export function fadeIn(time: number): AnimationTriggerMetadata {
  return trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate(time, style({ opacity: 1 }))])])
}

export function fadeOut(time: number): AnimationTriggerMetadata {
  return trigger('fadeOut', [transition(':leave', [style({ opacity: 100 }), animate(time, style({ opacity: 0 }))])])
}

export function fadeInCraft(time: number): AnimationTriggerMetadata {
  return trigger('fadeInCraft', [
    transition(':enter', [
      style({ transform: 'translate(-50%, 100px)' }),
      animate(
        time,
        keyframes([
          style({ transform: 'translate(-50%, 0)', offset: 0.25 }),
          style({ transform: 'translate(-50%, -10px)', offset: 0.5 }),
          style({ transform: 'translate(-50%, 0)', offset: 1 }),
        ]),
      ),
    ]),
  ])
}

export function fadeOutCraft(time: number): AnimationTriggerMetadata {
  return trigger('fadeOutCraft', [
    transition(':leave', [
      style({ transform: 'translate(-50%, 0)' }),
      animate(
        time,
        keyframes([
          style({ transform: 'translate(-50%, 0)', offset: 0.25 }),
          style({ transform: 'translate(-50%, -10px)', offset: 0.5 }),
          style({ transform: 'translate(-50%, 100px)', offset: 1 }),
        ]),
      ),
    ]),
  ])
}

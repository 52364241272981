@if (globalService.barsVisibility()) {
  <div class="canvas__bottom-left-bar canvas__bar" @fadeIn @fadeOut>
    <button
      type="button"
      class="canvas__control me-2"
      [appTooltip]="'Undo'"
      data-tooltip-nowrap
      data-tooltip-top-center
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            id="Vector"
            d="M7.33337 12L10.3334 12C11.9902 12 13.3334 10.6569 13.3334 9C13.3334 7.34313 11.9902 6 10.3334 6L2.66671 6"
            stroke="#D4D4D4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M4.66675 8C4.66675 8 2.66675 6.527 2.66675 6C2.66675 5.47293 4.66675 4 4.66675 4"
            stroke="#D4D4D4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
    <button type="button" class="canvas__control" [appTooltip]="'Redo'" data-tooltip-nowrap data-tooltip-top-center>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            id="Vector"
            d="M8.66669 12L5.66669 12C4.00983 12 2.66669 10.6569 2.66669 9C2.66669 7.34313 4.00983 6 5.66669 6L13.3334 6"
            stroke="#D4D4D4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M11.3333 8C11.3333 8 13.3333 6.527 13.3333 6C13.3333 5.47293 11.3333 4 11.3333 4"
            stroke="#D4D4D4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>
}

import { Component } from '@angular/core'
import { fadeIn, fadeOut } from '@shared/animations/animation'
import { GlobalService } from '@shared/services/global.service'
import { TooltipDirective } from '@shared/directives/tooltip.directive'

@Component({
  animations: [fadeIn(500), fadeOut(300)],
  selector: 'app-bottom-left-bar',
  standalone: true,
  imports: [TooltipDirective],
  templateUrl: './bottom-left-bar.component.html',
  styleUrl: './bottom-left-bar.component.scss',
})
export class BottomLeftBarComponent {
  constructor(public globalService: GlobalService) {}
}

export enum Tools {
  affinity_mapping = 'affinity_mapping',
  chat = 'chat',
  design_review = 'design_review',
  desk_research = 'desk_research',
  how_might_we = 'how_might_we',
  interview_guide = 'interview_guide',
  persona = 'persona',
  site_map = 'site_map',
  stakeholders_kickoff = 'stakeholders_kickoff',
  summarize = 'summarize',
  user_journey_mapping = 'user_journey_mapping',
  ux_copywriting = 'ux_copywriting',
}

export enum ToolsCategories {
  ideate = 'ideate',
  discover = 'discover',
  define = 'define',
  design = 'design',
}

<button
  type="button"
  class="canvas__dropdown canvas__export-btn"
  (click)="visible.set(!visible())"
  appClickOutside
  (clickedOutside)="visible.set(false)"
>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 2.99996V9.66663M7.99967 2.99996C7.53287 2.99996 6.66069 4.32949 6.33301 4.66663M7.99967 2.99996C8.46647 2.99996 9.33867 4.32949 9.66634 4.66663"
      stroke="#737373"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3337 11C13.3337 12.6547 12.9883 13 11.3337 13H4.66699C3.01233 13 2.66699 12.6547 2.66699 11"
      stroke="#737373"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <span class="ms-2">Export</span>
  <div class="canvas__export-menu-wrapper" [class.!block]="visible()">
    <div class="canvas__menu">
      <div class="canvas__links">
        <button type="button" class="canvas__link">Export to Figma</button>
        <button type="button" class="canvas__link">Export to Miro</button>
      </div>
      <div class="canvas__links canvas__links_divider">
        <button type="button" class="canvas__link">Copy to clipboard</button>
      </div>
      <div class="canvas__links">
        <button type="button" class="canvas__link">Export to TXT</button>
        <button type="button" class="canvas__link">Export to CSV</button>
      </div>
    </div>
  </div>
</button>

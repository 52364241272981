<button
  type="button"
  class="canvas__dropdown me-2 group/button"
  (click)="visible.set(!visible())"
  appClickOutside
  (clickedOutside)="visible.set(false)"
>
  <span class="flex flex-row items-center">
    <img ngSrc="assets/images/icons/menu.svg" alt="" width="16" height="16" />
    <img
      ngSrc="assets/images/icons/menu-arrow.svg"
      alt=""
      width="16"
      height="16"
      class="ms-1 transition-transform group-hover/button:translate-y-0.5"
    />
  </span>
  <div class="canvas__main-menu-wrapper" [class.!block]="visible()">
    <div class="canvas__menu">
      <div class="canvas__links">
        <button type="button" class="canvas__link">Back to dashboard</button>
      </div>
      <div class="canvas__links canvas__links_divider">
        <button type="button" class="canvas__link canvas__link_submenu group">
          File
          <div class="canvas__main-menu-wrapper canvas__main-menu-wrapper_submenu group-hover:block">
            <div class="canvas__menu canvas__menu_wider">
              <div class="canvas__links">
                <button type="button" class="canvas__link">New project</button>
              </div>
              <div class="canvas__links canvas__links_divider">
                <button type="button" class="canvas__link">Import</button>
              </div>
              <div class="canvas__links">
                <button type="button" class="canvas__link">Export as</button>
              </div>
            </div>
          </div>
        </button>
        <button type="button" class="canvas__link canvas__link_submenu group">
          Edit
          <div class="canvas__main-menu-wrapper canvas__main-menu-wrapper_submenu group-hover:block">
            <div class="canvas__menu canvas__menu_wider">
              <div class="canvas__links">
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Undo
                    <span class="canvas__link-badge">Ctrl z</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Redo
                    <span class="canvas__link-badge">Ctrl y</span>
                  </span>
                </button>
              </div>
              <div class="canvas__links canvas__links_divider">
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Duplicate
                    <span class="canvas__link-badge">Ctrl d</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Delete
                    <span class="canvas__link-badge">⌫</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Copy
                    <span class="canvas__link-badge">Ctrl c</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Cut
                    <span class="canvas__link-badge">Ctrl x</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Paste
                    <span class="canvas__link-badge">Ctrl v</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </button>
        <button type="button" class="canvas__link canvas__link_submenu group">
          View
          <div class="canvas__main-menu-wrapper canvas__main-menu-wrapper_submenu group-hover:block">
            <div class="canvas__menu canvas__menu_wider">
              <div class="canvas__links">
                <button type="button" class="canvas__link canvas__link_checked">Show dot grid</button>
                <button type="button" class="canvas__link">Show square grid</button>
              </div>
              <div class="canvas__links canvas__links_divider">
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Zoom in
                    <span class="canvas__link-badge">Ctrl +</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Zoom out
                    <span class="canvas__link-badge">Ctrl -</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    Zoom to fit
                    <span class="canvas__link-badge">Ctrl ++</span>
                  </span>
                </button>
              </div>
              <div class="canvas__links">
                <button type="button" class="canvas__link">200%</button>
                <button type="button" class="canvas__link">
                  <span class="canvas__link-wrapper">
                    100%
                    <span class="canvas__link-badge">Ctrl 0</span>
                  </span>
                </button>
                <button type="button" class="canvas__link">50%</button>
              </div>
            </div>
          </div>
        </button>
        <button type="button" class="canvas__link canvas__link_submenu group">
          Preferences
          <div class="canvas__main-menu-wrapper canvas__main-menu-wrapper_submenu group-hover:block">
            <div class="canvas__menu canvas__menu_wider">
              <div class="canvas__links">
                <button type="button" class="canvas__link">Invert zoom direction</button>
              </div>
            </div>
          </div>
        </button>
      </div>
      <div class="canvas__links">
        <button type="button" class="canvas__link canvas__link_submenu group">
          Help &amp; account
          <div class="canvas__main-menu-wrapper canvas__main-menu-wrapper_submenu group-hover:block">
            <div class="canvas__menu canvas__menu_wider">
              <div class="canvas__links">
                <button type="button" class="canvas__link">Tutorial videos</button>
              </div>
              <div class="canvas__links canvas__links_divider">
                <button type="button" class="canvas__link">Submit feedback</button>
                <button type="button" class="canvas__link">Ask the community</button>
                <button type="button" class="canvas__link">Our blog</button>
              </div>
              <div class="canvas__links">
                <button type="button" class="canvas__link">Legal summary</button>
                <button type="button" class="canvas__link">Account settings</button>
                <button type="button" class="canvas__link">Logout</button>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</button>

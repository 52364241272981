import { Directive, ElementRef, HostListener, output } from '@angular/core'

@Directive({
  selector: '[appClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  clickedOutside = output<EventTarget>()

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target: EventTarget) {
    if (!this.el.nativeElement.contains(target)) {
      this.clickedOutside.emit(target)
    }
  }
}

<button
  type="button"
  class="canvas__dropdown ms-2"
  (click)="visible.set(!visible())"
  appClickOutside
  (clickedOutside)="visible.set(false)"
>
  <div class="canvas__help-menu-wrapper" [class.!block]="visible()">
    <div class="canvas__menu">
      <div class="canvas__links">
        <a href="#" target="_blank" class="canvas__link">Tutorial videos</a>
      </div>
      <div class="canvas__links canvas__links_divider">
        <a href="#" target="_blank" class="canvas__link">Submit feedback</a>
        <a href="#" target="_blank" class="canvas__link">Ask the community </a>
        <a href="#" target="_blank" class="canvas__link">Our blog</a>
      </div>
      <div class="canvas__links">
        <a href="#" target="_blank" class="canvas__link">
          <span class="canvas__link-wrapper">
            Version
            <span class="canvas__link-badge">0.0.1</span>
          </span>
        </a>
        <a href="#" target="_blank" class="canvas__link">Legal summary</a>
      </div>
    </div>
  </div>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.625 4.03333C4.625 2.35807 5.98302 1 7.65833 1C9.33364 1 10.6917 2.35807 10.6917 4.03333C10.6917 4.63719 10.5153 5.19984 10.211 5.67254C9.30452 7.08137 7.65833 8.42468 7.65833 10.1V10.8583"
      stroke="#737373"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path d="M7.64648 15H7.66014" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</button>

import { Component, signal } from '@angular/core'
import { ToolsMenuComponent } from '@shared/components/tools-menu/tools-menu.component'
import { TooltipDirective } from '@shared/directives/tooltip.directive'
import { GlobalService } from '@shared/services/global.service'
import { fadeIn, fadeOut } from '@shared/animations/animation'

@Component({
  animations: [fadeIn(500), fadeOut(300)],
  selector: 'app-tools-bar',
  standalone: true,
  imports: [ToolsMenuComponent, TooltipDirective],
  templateUrl: './tools-bar.component.html',
  styleUrl: './tools-bar.component.scss',
})
export class ToolsBarComponent {
  mainToolsVisible = signal<boolean>(false)

  constructor(public globalService: GlobalService) {}
}

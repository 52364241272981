import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'scaleToPercent',
  standalone: true,
})
export class ScaleToPercentPipe implements PipeTransform {
  transform(scale: number): string {
    const percent = scale * 100
    return Math.round(percent) + '%'
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { ToolItem } from '@shared/interfaces'

@Pipe({
  name: 'tools',
  standalone: true,
})
export class ToolsPipe implements PipeTransform {
  transform(tools: ToolItem[], filter: string): ToolItem[] {
    return tools.filter(tool => tool.name.toLowerCase().includes(filter.toLowerCase()))
  }
}

<div class="canvas__zoom">
  <div>
    <button
      type="button"
      (click)="fullScreen()"
      class="canvas__control"
      [appTooltip]="'Zoom to Fit'"
      data-tooltip-nowrap
      data-tooltip-top-center
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.46577 10.5339L10.5335 5.46614M5.46577 10.5339C5.17006 10.2382 5.45979 8.84351 5.46577 8.42237M5.46577 10.5339C5.76149 10.8296 7.15621 10.5399 7.57734 10.5339M10.5335 5.46614C10.2379 5.17043 8.84314 5.46016 8.42201 5.46615M10.5335 5.46614C10.8293 5.76186 10.5395 7.15657 10.5335 7.57771"
          stroke="#737373"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.66699 7.99996C1.66699 5.0144 1.66699 3.52162 2.59449 2.59412C3.52199 1.66663 5.01477 1.66663 8.00033 1.66663C10.9859 1.66663 12.4787 1.66663 13.4062 2.59412C14.3337 3.52162 14.3337 5.0144 14.3337 7.99996C14.3337 10.9855 14.3337 12.4783 13.4062 13.4058C12.4787 14.3333 10.9859 14.3333 8.00033 14.3333C5.01477 14.3333 3.52199 14.3333 2.59449 13.4058C1.66699 12.4783 1.66699 10.9855 1.66699 7.99996Z"
          stroke="#737373"
        />
      </svg>
    </button>
    <button
      type="button"
      (click)="zoomOut()"
      class="canvas__control"
      [appTooltip]="'Zoom Out'"
      data-tooltip-nowrap
      data-tooltip-top-center
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3337 8H2.66699" stroke="#737373" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
    <button
      type="button"
      (click)="zoomIn()"
      class="canvas__control"
      [appTooltip]="'Zoom In'"
      data-tooltip-nowrap
      data-tooltip-top-center
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 2.66663V13.3333"
          stroke="#737373"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.66699 8H13.3337"
          stroke="#737373"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <button
    type="button"
    class="canvas__control px-1"
    (click)="resetZoom()"
    [appTooltip]="'Zoom to 100%'"
    data-tooltip-nowrap
    data-tooltip-top-center
  >
    @if (canvas() && zoom()) {
      {{ getScale() | scaleToPercent }}
    }
  </button>
</div>

import { Component, signal } from '@angular/core'
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive'

@Component({
  selector: 'app-help-menu',
  standalone: true,
  imports: [ClickOutsideDirective],
  templateUrl: './help-menu.component.html',
  styleUrl: './help-menu.component.scss',
})
export class HelpMenuComponent {
  visible = signal<boolean>(false)
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ChatMessage, PredictionResponse } from '../../interfaces'
import { NgClass } from '@angular/common'

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
})
export class ChatComponent implements OnInit {
  @ViewChild('messagesScroller') messagesScroller: ElementRef | undefined

  followUpPrompts: string[] = []
  form: FormGroup
  loading = false
  messages: ChatMessage[] = []

  overrideConfig = {
    sessionId: '',
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      query: ['Hello World!', Validators.required],
    })
  }

  async ngOnInit() {
    await this.submit()
  }

  async query(data): Promise<PredictionResponse> {
    const response = await fetch(
      'https://qoqo-ai.app.flowiseai.com/api/v1/prediction/b9c78057-66ab-4e9b-b3be-176f5b6c363f',
      {
        headers: {
          Authorization: 'Bearer yd3RlmAKIh_OBelaTzudjtqso6vcAfAuemdkddCvqOI',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          ...data,
          overrideConfig: this.overrideConfig,
          history: this.messages,
        }),
      },
    )
    const json = await response.json()
    const followUpPrompts = JSON.parse(json.followUpPrompts)
    this.followUpPrompts = JSON.parse(followUpPrompts)
    return json
  }

  async submit() {
    if (this.loading) {
      return
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    const query = this.form.get('query')?.value
    this.loading = true
    this.scrollToEnd()
    const response: PredictionResponse = await this.query({
      question: query,
    })
    this.overrideConfig.sessionId = response.sessionId
    this.form.get('query')?.setValue('')
    this.loading = false
    this.messages.push({ role: 'userMessage', content: response.question })
    this.messages.push({ role: 'apiMessage', content: response.text })
    this.scrollToEnd()
  }

  scrollToEnd() {
    setTimeout(() => {
      this.messagesScroller?.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }, 100)
  }

  async selectFollowUpPrompt(question: string) {
    if (this.loading) {
      return
    }
    this.form.get('query')?.setValue(question)
    await this.submit()
  }
}

import { Component, input } from '@angular/core'
import { GlobalService } from '@shared/services/global.service'
import { fadeIn, fadeOut } from '@shared/animations/animation'
import { HelpMenuComponent } from '@shared/components/help-menu/help-menu.component'
import { ZoomMenuComponent } from '@shared/components/zoom-menu/zoom-menu.component'
import { FCanvasComponent, FFlowModule, FZoomDirective } from '@foblex/flow'

@Component({
  animations: [fadeIn(500), fadeOut(300)],
  selector: 'app-bottom-right-bar',
  standalone: true,
  imports: [FFlowModule, HelpMenuComponent, ZoomMenuComponent],
  templateUrl: './bottom-right-bar.component.html',
  styleUrl: './bottom-right-bar.component.scss',
})
export class BottomRightBarComponent {
  canvas = input.required<FCanvasComponent | undefined>()
  zoom = input.required<FZoomDirective | undefined>()

  constructor(public globalService: GlobalService) {}
}

import { Directive, ElementRef, HostListener, input } from '@angular/core'

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective {
  appTooltip = input.required<string>()

  constructor(private elementRef: ElementRef) {}

  @HostListener('mouseenter', ['$event']) mouseenter(event: Event) {
    if (this.appTooltip() === '') {
      return
    }

    const sourceEl = this.elementRef.nativeElement
    if (!sourceEl.classList.contains('absolute')) {
      sourceEl.style.position = 'relative'
    }
    const tooltipWrapper = document.createElement('div')
    tooltipWrapper.classList.add('tooltip-wrapper')
    const tooltip = document.createElement('span')
    tooltip.classList.add('tooltip')

    const el = event.target as HTMLElement
    // Here we will handle tooltip params set via data-tooltip-param name html element attributes
    tooltip.style.width = el.dataset['tooltipWidth'] ?? 'auto'
    if (el.dataset['tooltipNowrap'] !== undefined) {
      tooltip.style.whiteSpace = 'nowrap'
    }
    if (el.dataset['tooltipTopCenter'] !== undefined) {
      tooltip.classList.add('tooltip-top-center')
    }
    if (el.dataset['tooltipBottomCenter'] !== undefined) {
      tooltip.classList.add('tooltip-bottom-center')
    }
    if (el.dataset['tooltipRightCenter'] !== undefined) {
      tooltip.classList.add('tooltip-right-center')
    }

    tooltip.innerText = this.appTooltip()
    tooltipWrapper.appendChild(tooltip)
    sourceEl.appendChild(tooltipWrapper)
  }

  @HostListener('mouseleave') mouseleave() {
    this.elementRef.nativeElement.querySelector('.tooltip-wrapper')?.remove()
  }
}

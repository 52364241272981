import { Component } from '@angular/core'
import { TooltipDirective } from '@shared/directives/tooltip.directive'
import { fadeIn, fadeInCraft, fadeOut, fadeOutCraft } from '@shared/animations/animation'
import { GlobalService } from '@shared/services/global.service'

@Component({
  animations: [fadeIn(500), fadeOut(500), fadeInCraft(500), fadeOutCraft(500)],
  selector: 'app-bottom-center-bar',
  standalone: true,
  imports: [TooltipDirective],
  templateUrl: './bottom-center-bar.component.html',
  styleUrl: './bottom-center-bar.component.scss',
})
export class BottomCenterBarComponent {
  constructor(public globalService: GlobalService) {}
}

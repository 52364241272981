<div class="tools__list absolute left-12 top-0" appClickOutside (clickedOutside)="clickedOutside.emit(false)">
  <form [formGroup]="form" class="px-6 py-4 tools__search">
    <input type="text" name="search" formControlName="search" placeholder="Search tools" />
  </form>
  @if (form.get('search')?.value) {
    <div class="tools__wrapper" [class.tools__wrapper_empty]="!filteredTools.length">
      @for (tool of filteredTools; track tool.alias) {
        <ng-container *ngTemplateOutlet="item; context: { tool }"></ng-container>
      }
      @if (!filteredTools.length) {
        <div class="flex flex-col items-center justify-center">
          <img ngSrc="assets/images/icons/no-tools.svg" height="48" width="48" class="mt-4" alt="" />
          <div class="text-neutral-900 text-base my-2">No search results</div>
          <div class="text-neutral-700 text-xsm">Can’t find what you're looking for?</div>
          <a href="#" class="text-neutral-700 text-xsm underline">Request a new tool!</a>
        </div>
      }
    </div>
    @if (filteredTools.length) {
      <ng-container *ngTemplateOutlet="noTool"></ng-container>
    }
  } @else {
    <div class="tools__wrapper">
      <section>
        <header class="mb-2 text-xs text-neutral-400">Suggested</header>
        <div class="grid grid-flow-col grid-cols-2 gap-x-2">
          @for (tool of tools | suggestedTools; track tool.alias) {
            <a href="#" class="tools__list-item text-xsm !px-0 !flex !flex-col">
              <img [ngSrc]="'assets/images/icons/' + tool.icon + '.svg'" height="32" width="32" class="mb-2" alt="" />
              {{ tool.name }}
            </a>
          }
        </div>
      </section>
      <section>
        <ng-container *ngTemplateOutlet="header; context: { header: 'Ideate' }"></ng-container>
        @for (tool of tools | categoryTools: ToolsCategories.ideate; track tool.alias) {
          <ng-container *ngTemplateOutlet="item; context: { tool }"></ng-container>
        }
      </section>
      <section>
        <ng-container *ngTemplateOutlet="header; context: { header: 'Discover' }"></ng-container>
        @for (tool of tools | categoryTools: ToolsCategories.discover; track tool.alias) {
          <ng-container *ngTemplateOutlet="item; context: { tool }"></ng-container>
        }
      </section>
      <section>
        <ng-container *ngTemplateOutlet="header; context: { header: 'Define' }"></ng-container>
        @for (tool of tools | categoryTools: ToolsCategories.define; track tool.alias) {
          <ng-container *ngTemplateOutlet="item; context: { tool }"></ng-container>
        }
      </section>
      <section>
        <ng-container *ngTemplateOutlet="header; context: { header: 'Design' }"></ng-container>
        @for (tool of tools | categoryTools: ToolsCategories.design; track tool.alias) {
          <ng-container *ngTemplateOutlet="item; context: { tool }"></ng-container>
        }
      </section>
    </div>
    <ng-container *ngTemplateOutlet="noTool"></ng-container>
  }
</div>

<ng-template #header let-header="header">
  <header class="mt-4 mb-2 text-xs text-neutral-400">{{ header }}</header>
</ng-template>

<ng-template #item let-tool="tool">
  <a href="#" class="tools__list-item mb-2 group">
    <img [ngSrc]="'assets/images/icons/' + tool.icon + '.svg'" height="32" width="32" class="mb-2" alt="" />
    <div>
      <div class="text-neutral-900">{{ tool.name }}</div>
      <div class="text-neutral-500 text-xs">{{ tool.description }}</div>
    </div>
    <div class="tools__list-item-action">
      <img ngSrc="assets/images/icons/plus.svg" alt="" height="16" width="16" />
    </div>
  </a>
</ng-template>

<ng-template #noTool>
  <div class="px-6 py-2 text-neutral-400 text-xxxs">
    Can’t find what you're looking for? <a href="#" class="underline">Request a new tool!</a>
  </div>
</ng-template>

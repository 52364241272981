<form [formGroup]="form" (ngSubmit)="submit()" class="grid grid-rows-[1fr_minmax(80px,max-content)] overflow-y-auto">
  <div class="grid grid-rows-[1fr] overflow-y-auto">
    <div class="flex flex-col items-center justify-end" #messagesScroller>
      @if (messages.length) {
        <div class="messages">
          @for (message of messages; track message) {
            <div
              class="messages__wrapper"
              [ngClass]="{
                'flex flex-row items-center justify-end': message.role === 'userMessage',
              }"
            >
              <div
                class="messages__message"
                [ngClass]="{
                  'bg-slate-100 inline-flex flex-row': message.role === 'userMessage',
                  'bg-white': message.role === 'apiMessage',
                }"
              >
                {{ message.content }}
              </div>
            </div>
          }
        </div>
      }

      @if (followUpPrompts.length) {
        <div class="flex flex-row my-5 items-center justify-between">
          @for (question of followUpPrompts; track question) {
            <button
              type="button"
              class="rounded-lg bg-green-200 px-4 py-2 mx-5 text-xs"
              (click)="selectFollowUpPrompt(question)"
            >
              {{ question }}
            </button>
          }
        </div>
      }

      @if (loading) {
        <div class="loader"></div>
      }
    </div>
  </div>
  <div class="flex flex-row items-center justify-center">
    <input
      type="text"
      formControlName="query"
      id="query"
      class="border border-slate-500 rounded-lg p-2 grow"
      [readonly]="loading"
    />
    <button
      type="submit"
      class="bg-green-600 rounded-lg px-5 py-2 ms-4 text-white"
      [disabled]="loading || form.invalid"
    >
      @if (loading) {
        Sending...
      } @else {
        Send
      }
    </button>
  </div>
</form>

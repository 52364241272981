@if (globalService.barsVisibility()) {
  <div class="canvas__top-tools-bar canvas__bar" @fadeIn @fadeOut>
    <button
      type="button"
      (click)="mainToolsVisible.set(!mainToolsVisible())"
      class="canvas__control bg-white border border-neutral-200"
      [class.canvas__control_active]="mainToolsVisible()"
      [appTooltip]="'Tools menu'"
      data-tooltip-nowrap
      data-tooltip-right-center
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 2.66663V13.3333"
          stroke="#737373"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.66699 8H13.3337"
          stroke="#737373"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    @if (mainToolsVisible()) {
      <app-tools-menu (clickedOutside)="mainToolsVisible.set(false)"></app-tools-menu>
    }
  </div>
}

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core'
import { provideRouter } from '@angular/router'

import { routes } from './app.routes'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

export function loadAppConfig(): () => Promise<void> {
  return () =>
    new Promise<void>(resolve => {
      setTimeout(() => {
        resolve()
      }, 3000)
    })
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppConfig,
      multi: true,
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    importProvidersFrom([BrowserAnimationsModule]),
  ],
}

function flatMap(payload, callback) {
  return payload.reduce((result, x) => [...result, ...callback(x)], []);
}
function deepCloneNode(node) {
  const clone = node.cloneNode(true);
  const descendantsWithId = clone.querySelectorAll('[id]');
  const nodeName = node.nodeName.toLowerCase();
  clone.removeAttribute('id');
  for (let i = 0; i < descendantsWithId.length; i++) {
    descendantsWithId[i].removeAttribute('id');
  }
  if (nodeName === 'canvas') {
    transferCanvasData(node, clone);
  } else if (nodeName === 'input' || nodeName === 'select' || nodeName === 'textarea') {
    transferInputData(node, clone);
  }
  transferData('canvas', node, clone, transferCanvasData);
  transferData('input, textarea, select', node, clone, transferInputData);
  return clone;
}
function transferData(selector, node, clone, callback) {
  const descendantElements = node.querySelectorAll(selector);
  if (descendantElements.length) {
    const cloneElements = clone.querySelectorAll(selector);
    for (let i = 0; i < descendantElements.length; i++) {
      callback(descendantElements[i], cloneElements[i]);
    }
  }
}
let cloneUniqueId = 0;
function transferInputData(source, clone) {
  if (clone.type !== 'file') {
    clone.value = source.value;
  }
  if (clone.type === 'radio' && clone.name) {
    clone.name = `mat-clone-${clone.name}-${cloneUniqueId++}`;
  }
}
function transferCanvasData(source, clone) {
  const context = clone.getContext('2d');
  if (context) {
    try {
      context.drawImage(source, 0, 0);
    } catch (_a) {}
  }
}
function getDataAttrValueFromClosestElementWithClass(element, attr, cls) {
  return getClosestTarget(element, cls).dataset[attr];
}
function getClosestTarget(element, cls) {
  return element.closest(cls);
}
function isClosestElementHasClass(element, cls) {
  const target = getClosest(element, cls);
  return !!target && !target.classList.contains(cls + '-disabled');
}
function getClosest(element, cls) {
  return element.closest(cls);
}
function normalizeDomElementId(id) {
  if (!id.match(/^[a-zA-Z_]/)) {
    id = '_' + id;
  }
  return id.replace(/[^a-zA-Z0-9_\-:.]/g, '_');
}
class MouseEventExtensions {
  static isContextMenuEvent(event) {
    return event.type === 'contextmenu';
  }
  static isMouseMiddleButtonClickEvent(event) {
    return event.type === 'auxclick' && event.button === 1;
  }
  static isClickEvent(event) {
    return event.type === 'click';
  }
  static isCtrlPressed(event) {
    return event.ctrlKey || event.metaKey;
  }
  static isShiftPressed(event) {
    return event.shiftKey;
  }
  static fakeEvent() {
    return {
      shiftKey: false,
      ctrlKey: false,
      metaKey: false,
      altKey: false,
      preventDefault: () => {}
    };
  }
  static fakeCommandEvent() {
    return {
      shiftKey: false,
      ctrlKey: false,
      metaKey: true,
      altKey: false,
      type: 'click',
      preventDefault: () => {}
    };
  }
  static isCommandButton(isMetaKey, event) {
    return isMetaKey ? event.metaKey : event.ctrlKey;
  }
}
function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}
function castToBoolean(value) {
  return value != null && `${value}` !== 'false';
}
function castToEnum(value, varname, enm) {
  const result = enm[value.toUpperCase()] || getKeyByValue(enm, value.toLowerCase());
  if (result === undefined) {
    throw new Error(`Unknown ${varname}: ${value}. Accepted values: ${Object.keys(enm).join(', ')}`);
  }
  return result;
}
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
function isNumber(value) {
  return !isNaN(parseFloat(value)) && !isNaN(Number(value));
}
function castToNumber(value, fallbackValue = 0) {
  return isNumber(value) ? Number(value) : fallbackValue;
}

/**
 * Generated bundle index. Do not edit.
 */

export { MouseEventExtensions, castToBoolean, castToEnum, castToNumber, deepClone, deepCloneNode, flatMap, generateGuid, getDataAttrValueFromClosestElementWithClass, isClosestElementHasClass, isNumber, normalizeDomElementId };

import { Component, input } from '@angular/core'
import { SpinnerComponent } from '@shared/components/spinner/spinner.component'
import { fadeIn, fadeOut } from '@shared/animations/animation'

@Component({
  animations: [fadeIn(500), fadeOut(500)],
  selector: 'app-online',
  standalone: true,
  imports: [SpinnerComponent],
  templateUrl: './online.component.html',
  styleUrl: './online.component.scss',
})
export class OnlineComponent {
  online = input.required<boolean | undefined>()
}

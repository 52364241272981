import { ChangeDetectionStrategy, Component, HostListener, ViewChild } from '@angular/core'
import { FCanvasChangeEvent, FCanvasComponent, FFlowModule, FZoomDirective } from '@foblex/flow'
import { BottomCenterBarComponent } from '@shared/components/bars/bottom-center-bar/bottom-center-bar.component'
import { GlobalService } from '@shared/services/global.service'
import { BottomLeftBarComponent } from '@shared/components/bars/bottom-left-bar/bottom-left-bar.component'
import { BottomRightBarComponent } from '@shared/components/bars/bottom-right-bar/bottom-right-bar.component'
import { TopLeftBarComponent } from '@shared/components/bars/top-left-bar/top-left-bar.component'
import { TopRightBarComponent } from '@shared/components/bars/top-right-bar/top-right-bar.component'
import { ToolsBarComponent } from '@shared/components/bars/tools-bar/tools-bar.component'

@Component({
  selector: 'app-canvas',
  standalone: true,
  imports: [
    FFlowModule,
    BottomCenterBarComponent,
    BottomLeftBarComponent,
    BottomRightBarComponent,
    TopLeftBarComponent,
    TopRightBarComponent,
    ToolsBarComponent,
  ],
  providers: [GlobalService],
  templateUrl: './canvas.component.html',
  styleUrl: './canvas.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasComponent {
  @ViewChild(FZoomDirective, { static: true })
  public fZoomDirective!: FZoomDirective | undefined

  @ViewChild(FCanvasComponent, { static: true })
  public fCanvasComponent!: FCanvasComponent | undefined

  constructor(private globalService: GlobalService) {}

  canvasChange(event: FCanvasChangeEvent) {
    console.log(event)
  }

  @HostListener('window:keypress', ['$event'])
  shortcutsHandler(event: KeyboardEvent) {
    if (event.ctrlKey) {
      switch (event.key) {
        case '\x1C':
          this.globalService.barsVisibility.set(!this.globalService.barsVisibility())
          break
        case '0':
          break
        case '+':
          break
        case '-':
          break
      }
    }
  }
}
